@font-face {
	font-family: BM JUA_TTF;
	src: url(/fonts/BMJUA/BMJUA_ttf.ttf);
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: LOTTERIA CHAB;
	src: url(/fonts/chab/chab.ttf);
	font-weight: 700;
	font-display: swap;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: BM JUA_TTF, LOTTERIA CHAB, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  cursor: url("/source/cursor_default.png"), auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

button {
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: url("/source/cursor_pointer.png"), pointer;
}

select {
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: url("/source/cursor_pointer.png"), pointer;
}

a {
  cursor: url("/source/cursor_pointer.png"), pointer;
}

label {
  cursor: url("/source/cursor_pointer.png"), pointer;
}